import {FullScreenContainer,BorderBox1,Decoration11,Decoration3,Decoration5,Decoration8,BorderBox3,WaterLevelPond,BorderBox10,BorderBox11,BorderBox13} from '@jiaminghi/data-view-react'
import teabg from '../img/teabg1.png'
import Charts from '@jiaminghi/charts'
import { Line,Gauge,Pie } from '@ant-design/charts';
import React, { useState, useEffect,useRef } from 'react';
import {  hexCharCodeToStr, trimRight } from '../utils/public'
import style from './Ty.css'
import dayjs from 'dayjs'
function sortId(a,b){
  return a.dayhour - b.dayhour
}
var script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = 'https://cdn.jsdelivr.net/npm/seamscroll@0.0.12/build/seamscroll.min.js'
    script.onload = function () {
       // @ts-ignore
      // seamscroll.init({
      //   dom: document.getElementById('demo2'),
      //   direction: 2
      // })
    }
    document.head.appendChild(script)


 // 获取 URL 参数
 const  getQueryVariable = (variable) =>{
  var query = decodeURIComponent(window.location.search.substring(1));
  
  var vars = query.split("&");
  for (var i=0;i<vars.length;i++) {
          var pair = vars[i].split("=");
          if(pair[0] == variable){return pair[1];}
  }
  return(false);
}



  const  config1 = {
    percent: 1,
    range: { color: 'l(0) 0:#bde8ff 1:#9ec9ff' },
    startAngle: Math.PI,
    endAngle: 2 * Math.PI,
    indicator: null,
    statistic: {
      title: {
        offsetY: -36,
        style: {
          fontSize: '26px',
          color: 'white',
        },
        formatter: function formatter() {
          return '100%';
        },
      },
      content: {
        style: {
          fontSize: '14px',
          lineHeight: '34px',
          color: 'white',
        },
        formatter: function formatter() {
          return '设备在线率';
        },
      },
    },
  };

  var data6 = [
    {
      type: '异常',
      value: 50,
    },
    {
      type: '故障',
      value: 50,
    },
  ];
  const config6 = {
    appendPadding: 10,
    data: data6,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{value}',
      style: {
        textAlign: 'center',
        fontSize: 14,
      },
    },
    interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        formatter: function formatter() {
          return '';
        },
      },
    },
  };


const Ty = () => {
  
    const [weainfo,setweainfo]= useState({}); //天气信息

    
    const maxcs = useRef()
    const nowcs = useRef()
    const [nowcss,setnowcss] = useState(0);
    const intervalRef = useRef()
    const [sdata,setsdata]= useState([]); //设备信息

    const [title,settitle]= useState("");

    const [chatdata2,setchatdata2]= useState([]);
    const [chatdata3,setchatdata3]= useState([]);
    const [chatdata4,setchatdata4]= useState([]);
    const [chatdata5,setchatdata5]= useState([]);
    var config2 = {
      data: chatdata2,
      padding: 'auto',
      xField: 'dayhour',
      yField: 'value',
      
    };

    var config3 = {
      data: chatdata3,
      padding: 'auto',
      xField: 'dayhour',
      yField: 'value',
     
    };

    var config4 = {
      data: chatdata4,
      padding: 'auto',
      xField: 'dayhour',
      yField: 'value',
    };

    var config5 = {
      data: chatdata5,
      padding: 'auto',
      xField: 'dayhour',
      yField: 'value',
    };
    let requfidata = {
      reqdata:[]
    }
    function unique (arr) {
      return Array.from(new Set(arr))
     }
   
    useEffect(() => {
      window.onload = function() {
       
      }
      getwea()
      let pare = getQueryVariable("userid")
      let title = getQueryVariable("title")
      settitle(title)
     
      getdata2(pare)
      getdata3(pare)
      getdata4(pare)
      getdata5(pare)
     
      setInterval(()=>{
        getwea()
        getdata2(pare)
        getdata3(pare)
        getdata4(pare)
        getdata5(pare)
      }, 1000*60*60)
      getdata(pare)
     
        // 获取设备
        fetch('cent/getlistbyuserorder?userid='+pare,{
          method: "GET",
        })
        .then((res)=>{
            return res.json(); //请求成功，获请求元数据
        })
        .then((result)=>{
          if(result.code != 0){
            
          }else{
            let maxcss = 0
            result.obj.map((item,key) =>{
              item.cs = Math.floor(key/4) 
              const a = Object.keys(item)[0]
              item.devicename =  item[a][0].devicename
              maxcss = Math.floor(key/4) 
            })
            nowcs.current = 0
            maxcs.current = maxcss
            let arr = []
            result.obj.map((item,key) =>{
            
              const a = Object.keys(item)[0]
                for(var i=0;i<item[a].length;i+=3){
                 
                    arr.push({cate:a,data:item[a].slice(i,i+3),devicename:item['devicename']});
                }
            })
            setsdata(arr)
            //遍历生成html代码
            let htmlstrl = ``

            
            arr.map((item,key) =>{
                if(htmlstrl == ""){
                  htmlstrl = `<ul id="demo1" style="padding-left: 2vw;">`
                }
                if(key == arr.length){
                  htmlstrl = `</ul>`
                }
                htmlstrl = htmlstrl + `
                <li  style="line-height: 12vh;">
                    <div style="float:left;color:white;width:16%;height:80%">
                      <div style="background-color: #00a7ff;padding: 0.4vw;border-radius: 1vw;font-size: 2vh;line-height: 9vh;text-align:center;font-weight: bold;">
                          ${item.cate}
                        </div>
                    </div>
                    ${item['data'].map(item2 => `
                    <div style="float:left;color:white;width:28%;height:80%">
                      <div style="background-color: #495daa;padding: 0.4vw;margin-left: 0.5vw;border-radius: 1vw;font-size: 2vh;line-height: 7vh;">
                          <div style="float: left;width: 47%;line-height: 9vh;border-right: 1px solid rgba(0, 0, 0, 0.6);height: 100%;text-align: center;">
                                ${item2.name}
                          </div>
                        
                          <div style="float: left;width: 47%;font-size: 1vh;line-height: 4.5vh;margin-left: 3%;">
                                ${item2.typev=="温湿度"?
                                `温度：<span id="${item2.wy+'wd'}">${item2.wd!=undefined?item2.wd:""}</span>℃<br/>
                                湿度：<span id="${item2.wy+'sd'}">${item2.sd!=undefined?item2.sd:""}</span>%`
                                
                                :""}
                                ${item2.typev=="含氧量"?
                                `含氧：<span id="${item2.wy+'hyl'}">${item2.hyl!=undefined?item2.hyl:""}</span>%`
                                :""}
                                
                          </div>
                          <div style="clear:both"></div>
                      </div>
                    </div>
                    `).join('')}
                
                     
               </li>
                `
              })
                 
                    

            var div1 = document.getElementById("div1");
            div1.innerHTML=htmlstrl;
         
            
            requfidata.reqdata = arr
            let urlStr = ""
           
            let reqarr = []
            requfidata.reqdata.forEach((v,i)=>{
              reqarr.push(v.devicename)
            })

            unique(reqarr).forEach((v,i)=>{
              urlStr =urlStr +  "&deviceName=" + v
            })
        
            eval(`seamscroll.init({dom: document.getElementById('demo1'),
                  hoverStop:false,
                })`)
            let ws = new WebSocket("wss://iotmsgapp.connconn.com/ws?" + urlStr);
            ws.onopen = function () {
              ws.send(`{"Data":"sadddddddddd"}`);
            };
            ws.onmessage = function (evt) {
              
              let received_msg = JSON.parse(evt.data);
              if(received_msg.isrmsg == 1){ //是消息
                  let ob= ""
                  let msg = ""
                  let msgn = ""
                for (let index = 0; index < requfidata.reqdata.length; index++) {
              
                  const val = requfidata.reqdata[index];

                  let cj = Object.keys(val)[0] //车间key
                
                  for (let ii = 0; ii < val[cj].length; ii++) {
                    const vv = val[cj][ii];
                    if (received_msg.deviceName+"-"+received_msg.equipmentId == vv.wy){
                      
                      ob = requfidata.reqdata[index][cj][ii]
                      if(ob.data == 1){ // 失联
                        msg = cj +ob.name+ " 失去了连接"
                      }else{
                        if(ob.state0 == 1){ //红 停止
                          msg = cj +ob.name+ " 停止"
                        }else if(ob.state1 == 1){ //黄 
                          msg = cj +ob.name+ " 警告"
                        }else if(ob.state2 == 1){ //绿
                          msg = cj +ob.name+ " 运行中"
                        }else{
                          msg = cj +ob.name+ " 设备已断电"
                        }
                      }
                    }
                    
                  }
                  
                }
                
              }else{ //是数据
                for (let index = 0; index < requfidata.reqdata.length; index++) {
                  const val = requfidata.reqdata[index];
                  for (let ii = 0; ii < val['data'].length; ii++) {
                    const vv = val['data'][ii];
                    if (received_msg.deviceName+"-"+received_msg.equipmentId == vv.wy){
                      requfidata.reqdata[index]['data'][ii].state0 = received_msg.input_0
                      requfidata.reqdata[index]['data'][ii].state1 = received_msg.input_1
                      requfidata.reqdata[index]['data'][ii].state2 = received_msg.input_2
                      requfidata.reqdata[index]['data'][ii].swhitch1 = received_msg.output_0
                      requfidata.reqdata[index]['data'][ii].swhitch2 = received_msg.output_1
                      requfidata.reqdata[index]['data'][ii].swhitch3 = received_msg.output_2
                      if(received_msg.bar_code != undefined && received_msg.bar_code !='' ) {
                        if(received_msg.bar_code.length == 18){
                          let a = hexCharCodeToStr(received_msg.bar_code)
                          let b = trimRight(a.substring(3))
                         
                          document.getElementById(vv.wy+'hyl').innerHTML = b
                          requfidata.reqdata[index]['data'][ii].hyl = b 
                        }else if(received_msg.bar_code.length == 13){
                          let a = received_msg.bar_code.split(",");
                          let wd = a[0].substring(2)
                          let sd = a[1].substring(2) 
                          if(wd>0){
                            
                            document.getElementById(vv.wy+'wd').innerHTML = wd
                            requfidata.reqdata[index]['data'][ii].wd  = wd
                          }
                          if(sd>0){
                           
                            document.getElementById(vv.wy+'sd').innerHTML = sd
                            requfidata.reqdata[index]['data'][ii].sd = sd
                          }
                        }else{
                        
                        }
                      
                      }
                      
                      setsdata([...requfidata.reqdata])
                    }
                  }
                  
                }
              }
            };

          }
          
          
        })
        .catch((err)=>{
            //出错了
        })
      },[]);
      const upmockdata = async () => {
        setnowcss(nowcs.current)
      }
    useEffect(()=>{
      const id = setInterval(()=>{
        var today = new Date(),
        date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()+"-"+today.getHours()+"-"+today.getMinutes()+"-"+today.getSeconds();
        if(nowcs.current == maxcs.current){
          nowcs.current = 0
        }else{
          nowcs.current =nowcs.current+1
        }
        upmockdata()
        
      },10000)
      
      intervalRef.current = id;
      return () => {
        if(intervalRef.current){
          clearInterval(intervalRef.current)
        }
      }
    },[])



    const getwea = () => {
        // 获取天气
        fetch('jdapi/jisuapi/weather?city=东莞&cityid=&citycode=&appkey=a915e6394028a50909a5cb04169928cb',{
          method: "GET",
          // headers: {
          //      'content-type': 'application/json'
          // }
        })
        .then((res)=>{
          return res.json(); //请求成功，获请求元数据
        })
        .then((result)=>{
        if(result.code == '10000'){
          setweainfo(result['result']['result'])
        }
        })
        .catch((err)=>{
          //出错了
        })
    }
    // 获取数据
    const getdata2 = (pare) => {
      fetch('dataapi/fxbbtv?userid='+pare+'&a1=iot&a2=84&a3=&a4=&a5=month&a6=wd&a7=&a8=&a9=&a10=',{
        method: "GET",
      })
      .then((res)=>{
        return res.json(); //请求成功，获请求元数据
      })
      .then((result)=>{
      
        if(result.obj.data!=null){
          result.obj.data.sort(sortId)
          setchatdata2([...result.obj.data])
        }
      })
      .catch((err)=>{
        //出错了
      })
    }
    // 获取数据
    const getdata3 = (pare) => {
      fetch('dataapi/fxbbtv?userid='+pare+'&a1=iot&a2=84&a3=&a4=&a5=month&a6=sd&a7=&a8=&a9=&a10=',{
        method: "GET",
      })
      .then((res)=>{
        return res.json(); //请求成功，获请求元数据
      })
      .then((result)=>{
      
        if(result.obj.data!=null){
          result.obj.data.sort(sortId)
          setchatdata3([...result.obj.data])
        }
      })
      .catch((err)=>{
        //出错了
      })
    }
    // 获取数据
    const getdata4 = (pare) => {
      fetch('dataapi/fxbbtv?userid='+pare+'&a1=iot&a2=84&a3=&a4=&a5=today&a6=wd&a7=&a8=&a9=&a10=',{
        method: "GET",
      })
      .then((res)=>{
        return res.json(); //请求成功，获请求元数据
      })
      .then((result)=>{
       
        if(result.obj.data!=null){
          result.obj.data.sort(sortId)
          setchatdata4([...result.obj.data])
        }
      })
      .catch((err)=>{
        //出错了
      })
    }

     const getdata5 = (pare) => {
    fetch('dataapi/fxbbtv?userid='+pare+'&a1=iot&a2=84&a3=&a4=&a5=today&a6=sd&a7=&a8=&a9=&a10=',{
      method: "GET",
    })
    .then((res)=>{
      return res.json(); //请求成功，获请求元数据
    })
    .then((result)=>{
     
      if(result.obj.data!=null){
        result.obj.data.sort(sortId)
        setchatdata5([...result.obj.data])
      }
    })
    .catch((err)=>{
      //出错了
    })
    }
    // 获取数据
    const getdata = (pare) => {
      
      fetch('dataapi/fxbbtv?userid='+pare+'&a1=iot&a2=84&a3=&a4=&a5=&a6=&a7=&a8=&a9=&a10=',{
        method: "GET",
        // headers: {
        //      'content-type': 'application/json'
        // }
      })
      .then((res)=>{
        return res.json(); //请求成功，获请求元数据
      })
      .then((result)=>{
      
      })
      .catch((err)=>{
        //出错了
      })
  }

    return (
        <FullScreenContainer>
          
            <div style={{backgroundImage:`url(${teabg})`,height:'100%'}} >
                <div style={{height:'10%'}}>
                        <div style={{width:'30%',float:'left'}}>
                             <Decoration8 style={{width: '100%', height: '4vh'}} />
                        </div>
                        <div style={{width:'40%',float:'left'}}>
                            <div style={{color:'white',fontSize:'4.5vh',textAlign:'center'}}>{title}</div>
                            {/* <Decoration5 style={{width: '100%', height: '4vh'}} /> */}
                        </div>
                        <div style={{width:'30%',float:'left'}}>
                                <Decoration8 reverse={true} style={{width: '100%', height: '4vh'}} />
                        </div>
                </div>
              
                        <div style={{float:'left',width:'20%',height:'90%'}}>
                            <div style={{height:'30%',padding:'1vh'}}>
                                    <BorderBox10>
                                        <div style={{color:'white',fontSize:'2vh',textAlign:'center',height:'15%'}}>
                                            设备在线率
                                        </div>
                                        <div style={{textAlign:'center',height:'70%',paddingLeft:'1vw',paddingRight:'1vh'}}>
                                                <Gauge {...config1} />
                                        </div>
                                    </BorderBox10>
                            </div>
                            <div style={{height:'30%',padding:'1vh'}}>
                                    <BorderBox10>
                                        <div style={{color:'white',fontSize:'2vh',textAlign:'center',height:'15%'}}>
                                            本月温度浮动
                                        </div>
                                        <div style={{textAlign:'center',height:'80%',paddingLeft:'1vw',paddingRight:'1vh'}}>
                                                 <Line {...config2} />;
                                        </div>
                                    </BorderBox10>
                            </div>
                            <div style={{height:'30%',padding:'1vh'}}>
                                         <BorderBox10>
                                        <div style={{color:'white',fontSize:'2vh',textAlign:'center',height:'15%'}}>
                                            本月湿度浮动
                                        </div>
                                        <div style={{textAlign:'center',height:'80%',paddingLeft:'1vw',paddingRight:'1vh'}}>
                                                 <Line {...config3} />;
                                        </div>
                                    </BorderBox10>
                            </div>
                             
                            
                        </div>
                        <div style={{float:'left',width:'60%',height:'90%'}}>
                            <div style={{height:'10%'}}>
                                    <BorderBox13>
                                        <div style={{color:'white',fontSize:'3vh',paddingTop:'2.5vh',paddingLeft:'1vw',textAlign:'center',fontFamily:'楷体'}}>
                                          
                                        {weainfo['date']}&nbsp;&nbsp;{weainfo['week']}&nbsp;&nbsp;天气：{weainfo['weather']}&nbsp;&nbsp;气温：{weainfo['temp']}℃&nbsp;&nbsp;湿度：{weainfo['humidity']}%&nbsp;&nbsp;风向：{weainfo['winddirect']}
                                        {/* 2020-11-30&nbsp;&nbsp;&nbsp;星期一&nbsp;&nbsp;&nbsp;{weainfo['city']}&nbsp;&nbsp;&nbsp;天气:多云&nbsp;&nbsp;&nbsp;气温:18℃&nbsp;&nbsp;&nbsp;湿度:73% */}
                                          {/* 2020-10-31&nbsp;&nbsp;&nbsp;星期六&nbsp;&nbsp;&nbsp;{weainfo['city']}&nbsp;&nbsp;&nbsp;天气:小雨&nbsp;&nbsp;&nbsp;气温:24℃&nbsp;&nbsp;&nbsp;湿度:60% */}
                                        </div>
                                    </BorderBox13>
                            </div>
                             <div style={{height:'85%',padding:'1vh'}}>
                                    <BorderBox11>
                                        <div style={{color:'white',fontSize:'3.6vh',textAlign:'center',height:'10%',paddingTop:'1.5vh'}}>
                                            实时数据状态
                                        </div>
                                        <div id="div1" style={{height:'80%',paddingRight:'2vw',paddingTop:'1vh',marginTop:'1.5vh',overflow:'hidden'}}>
                                           
                                           

                                        </div>
                                    </BorderBox11>
                            </div>
                           
                        </div>
                        <div style={{float:'left',width:'20%',height:'90%'}}>
                            <div style={{height:'30%',padding:'1vh'}}>
                                     <BorderBox10>
                                        <div style={{color:'white',fontSize:'2vh',textAlign:'center',height:'15%'}}>
                                            今日温度
                                        </div>
                                        <div style={{textAlign:'center',height:'80%',paddingLeft:'1vh',paddingRight:'1vh'}}>
                                                <Line {...config4} />;
                                        </div>
                                    </BorderBox10>
                            </div>
                            <div style={{height:'30%',padding:'1vh'}}>
                                    <BorderBox10>
                                        <div style={{color:'white',fontSize:'2vh',textAlign:'center',height:'15%'}}>
                                            今日湿度
                                        </div>
                                        <div style={{textAlign:'center',height:'80%',paddingLeft:'1vh',paddingRight:'1vh'}}>
                                                 <Line {...config5} />;
                                        </div>
                                    </BorderBox10>
                            </div>
                            <div style={{height:'30%',padding:'1vh'}}>
                                    <BorderBox10>
                                        {/* <div style={{color:'white',fontSize:'2vh',textAlign:'center',height:'15%'}}>
                                            含氧量
                                        </div>
                                        <div style={{textAlign:'center',height:'85%',paddingLeft:'1vh',paddingRight:'1vh'}}>
                                               
                                                <table border="1" cellSpacing="0" style={{color:'white',width:'100%',border:'white'}}>
                                                      <tr>
                                                        <th style={{fontSize:'2vh',fontWeight:'normal'}}>采集点</th>
                                                        <th style={{fontSize:'2vh',fontWeight:'normal'}}>含氧量</th>
                                                      </tr>
                                                      
                                                      {
                                                          sdata.map((item,key) =>{
                                                            const a = Object.keys(item)[0]
                                                            return <>{
                                                              item[a].map((item2,key2) =>{
                                                                if(item2.typev == "含氧量"){
                                                                  return  <tr>
                                                                            <td style={{fontSize:'1.5vh'}}>{a}-{item2.name}</td>
                                                                            <td style={{fontSize:'1.5vh'}}>{item2.hyl}%</td>
                                                                          </tr>
                                                                }
                                                            })
                                                            }</>

                                                      })
                                                    }
                                                    
                                                    
                                                     
                                                    </table>
                                        </div> */}
                                    </BorderBox10>
                            </div>
                        </div>
                </div>
              
          
        </FullScreenContainer>
    )
}

export default Ty;